import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"

export default function () {
    const router = createRouter({
        history: createWebHashHistory(process.env.BASE_URL),
        linkActiveClass: "active-link",
        linkExactActiveClass: "exact-active-link",
        routes: [
            {
                path: "/",
                name: "landing",
                meta: { title: "Landing" },
                component: () => import("../views/LandingView.vue"),
            },
            {
                path: "/start",
                name: "start",
                meta: { title: "Start" },
                component: () => import("../views/StartView.vue"),
                children: [
                    {
                        path: ":doorCategoryId",
                        name: "start-door",
                        component: () => import("../views/StartView.vue"),
                        children: [
                            {
                                path: ":leftWindowCategoryId",
                                name: "start-left",
                                component: () => import("../views/StartView.vue"),
                                children: [
                                    {
                                        path: ":rightWindowCategoryId",
                                        name: "start-right",
                                        component: () => import("../views/StartView.vue"),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: "/_debug",
                name: "debug",
                component: () => import("../views/DebugView.vue"),
            },
            {
                path: "/email",
                name: "email",
                meta: { title: "Email" },
                component: () => import("../views/EmailView.vue"),
            },
            {
                path: "/login",
                name: "login",
                meta: { title: "Login" },
                component: () => import("../views/LoginView.vue"),
            },
            {
                path: "/register",
                name: "register",
                meta: { title: "Register" },
                component: () => import("../views/RegisterView.vue"),
            },
            {
                path: "/name-generator",
                name: "name-generator",
                meta: { title: "Name generator" },
                component: () => import("../views/NameGeneratorView.vue"),
            },
            {
                path: "/carousel/:categoryId",
                name: "carousel",
                meta: { title: "Carousel" },
                component: () => import("../views/CarouselView.vue"),
            },
            {
                path: "/result/:search",
                name: "result-search",
                meta: { title: "Search results" },
                component: () => import("../views/ResultView.vue"),
            },
            {
                path: "/result",
                name: "result",
                meta: { title: "Search results" },
                component: () => import("../views/ResultView.vue"),
            },
            {
                path: "/battery/:categoryId",
                name: "battery",
                meta: { title: "Battery" },
                component: () => import("../views/BatteryView.vue"),
            },
            {
                path: "/allstatement/:categoryId",
                name: "allstatement",
                meta: { title: "All Statements" },
                component: () => import("../views/AllStatementView.vue"),
            },
            {
                path: "/theater/:categoryId",
                name: "theater",
                meta: { title: "Theater" },
                component: () => import("../views/TheaterView.vue"),
            },
            {
                path: "/debating/:pollId",
                name: "debating",
                meta: { title: "Debating" },
                component: () => import("../views/DebatingView.vue"),
            },
            {
                path: "/error",
                name: "error",
                meta: { title: "Error" },
                component: () => import("../views/ErrorView.vue"),
            },
            {
                path: "/rules",
                name: "rules",
                meta: { title: "Rules of The Game" },
                component: () => import("../views/RulesView.vue"),
            },
            {
                path: "/about",
                name: "about",
                meta: { title: "About Us" },
                component: () => import("../views/AboutView.vue"),
            },
            {
                path: "/profile",
                redirect: { name: "personal-information" },
                component: () => import("../views/profile/BaseProfileView.vue"),
                children: [
                    {
                        path: "avatar",
                        name: "avatar",
                        meta: { title: "Avatar" },
                        component: () => import("../views/profile/AvatarView.vue"),
                    },
                    {
                        path: "statistics",
                        name: "statistics",
                        meta: { title: "Statistics" },
                        component: () => import("../views/profile/StatisticsView.vue"),
                        props: {
                            isPublic: false,
                        },
                    },
                    {
                        path: "personal-information",
                        name: "personal-information",
                        meta: { title: "Personal information" },
                        component: () => import("../views/profile/ProfileView.vue"),
                    },
                    {
                        path: "chatbox",
                        name: "chatbox",
                        component: () => import("../views/profile/ChatboxView.vue"),
                        meta: { title: "Chatbox", requiresAuth: true },
                    },
                    {
                        path: ":userId",
                        name: "user-profile",
                        meta: { title: "User profile" },
                        component: () => import("../views/profile/PublicProfileView.vue"),
                    },
                    {
                        path: ":userId/statistics",
                        name: "user-statistics",
                        meta: { title: "User Statistics" },
                        component: () => import("../views/profile/StatisticsView.vue"),
                        props: {
                            isPublic: true,
                        },
                    },
                ],
            },
            {
                path: "/:notFound(.*)",
                meta: { title: "Page not found!" },
                component: () => import("../views/NotFound.vue"),
            },
        ],
    })

    router.beforeEach((to, from, next) => {
        if (to.meta.title !== undefined) {
            document.title = "The Chickenpen - " + to.meta.title
        } else {
            document.title = "The Chickenpen"
        }

        const popover = document.querySelectorAll(".popover")
        popover.forEach((element) => {
            element.remove()
        })

        next()
    })

    return router
}
